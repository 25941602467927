import styled from 'styled-components'

export const StyledText = styled.div`
  font-size: 32px;
  min-height: 180px;

  @media screen and (min-width: 350px) {
    min-height: initial;
  }
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    font-size: 40px;
  }
  div {
    display: inline-block;
  }
  text-transform: uppercase;
  font-weight: 300;
  color: #4d4d4d;
`
