import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import { Container } from '../../components/styled'

export const StyledFirstSection = styled(Container)`
  min-height: 100vh;
  align-items: center;
  position: relative;
  padding-bottom: 0;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    position: initial;
  }
  > * {
    max-width: 650px;
    z-index: 3;
    width: 100%;
  }
`

export const Background = styled(Img)`
  bottom: 0;
  left: 0;
  right: 0;
  max-width: initial;
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.35),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 1)
  );
  max-height: 40vh;
  object-fit: cover;
  object-position: top;
  z-index: 2;
  width: 100vw;
  position: absolute !important;
  @media (orientation: landscape) and (max-width: ${props =>
      props.theme.responsive.medium}) {
    display: none;
  }
`

export const StyledLink = styled(Link)`
  z-index: 3;
  color: #666666;
  position: relative;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 8px;
  &:hover,
  &:focus {
    span {
      width: 100%;
    }
  }
  span {
    height: 1px;
    background: black;
    position: absolute;
    /* width: 100%; */
    left: 0;
    right: 0;
    bottom: -1px;
    width: 0;
    transition: width 0.5s ease-in-out;
  }
`
