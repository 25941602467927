import React from 'react'
import { Button, Box } from 'rebass'

import { AutoTyping } from '../../components/auto-typing'
import Layout from '../../components/Layout'
import { Paragraph } from '../../components/styled'

import { StyledFirstSection, Background, StyledLink } from './home.styled'

export const HomeTemplate = ({
  autoTypingBase,
  autoTypingTexts,
  homeDescription,
  backgroundImage,
}) => (
  <Layout>
    <StyledFirstSection>
      <AutoTyping base={`${autoTypingBase} `} quotes={autoTypingTexts} />
      <Paragraph as="p" py={[4, 3]} fontSize={1}>
        {homeDescription}
      </Paragraph>
      <Box mt={3} mb={5}>
        <StyledLink to="/proyectos" variant="primary">
          Ver nuestros proyectos
          <span />
        </StyledLink>
      </Box>
      <Background
        fluid={backgroundImage.fluid}
        backgroundColor="transparent"
        title={backgroundImage.title}
        alt={backgroundImage.description}
      />
    </StyledFirstSection>
  </Layout>
)
