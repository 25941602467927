import PropTypes from 'prop-types'
import React from 'react'
import Typing from 'react-typing-animation'
import { StyledText } from './auto-typing.styled'

const Quotes = quotes =>
  quotes.map((quote, index) => {
    const multiplier = quotes.length === index + 1 ? 0 : 1
    return (
      <React.Fragment key={quote}>
        <span>{quote}</span>
        <Typing.Delay ms={1000} />
        <Typing.Backspace count={quote.length + 1} />
        <Typing.Delay ms={750 * multiplier} />
      </React.Fragment>
    )
  })

export const AutoTyping = ({ base, quotes }) => (
  <StyledText>
    {base}
    <Typing loop speed={80}>
      <Typing.Delay ms={750} />
      {Quotes(quotes)}
    </Typing>
  </StyledText>
)

AutoTyping.propTypes = {
  base: PropTypes.string.isRequired,
  quotes: PropTypes.arrayOf(PropTypes.string).isRequired,
}
