import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Helmet from 'react-helmet'
import favicon from '../images/favicon.png'
import config from '../utils/siteConfig'

class SEO extends Component {
  render() {
    const {
      title,
      pagePath,
      description,
      image,
      isPage,
      isProject,
    } = this.props

    const pageUrl = pagePath ? `${config.siteUrl}/${pagePath}/` : config.siteUrl

    // Default Website Schema
    const schemaOrgJSONLD = [
      {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        url: config.siteUrl,
        name: config.siteTitle,
        alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
      },
    ]

    // Page SEO Schema
    if (isPage || isProject) {
      schemaOrgJSONLD.push({
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        url: pageUrl,
        name: title,
      })
    }

    // Blog Post Schema
    if (isProject) {
      schemaOrgJSONLD.push(
        {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              item: {
                '@id': config.siteUrl,
                name: config.siteTitle,
              },
            },
            {
              '@type': 'ListItem',
              position: 2,
              item: {
                '@id': pageUrl,
                name: title,
              },
            },
          ],
        },
        {
          '@context': 'http://schema.org',
          '@type': 'CreativeWork',
          author: config.author,
          image: image.src,
          name: title,
          description,
        }
      )
    }

    return (
      <Helmet>
        {/* General tags */}
        <title>{title}</title>
        <meta name="image" content={image.src} />
        <meta name="description" content={description} />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href={favicon} />
        {/* Schema.org tags */}
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>

        {/* OpenGraph tags */}
        <meta property="og:title" content={title} />

        <meta property="og:url" content={pageUrl} />
        <meta property="og:image" content={image.src} />
        <meta property="og:image:width" content={image.width} />
        <meta property="og:image:height" content={image.height} />
        <meta property="og:description" content={description} />
      </Helmet>
    )
  }
}

SEO.propTypes = {
  title: PropTypes.string,
  pagePath: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.objectOf({
    src: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  isPage: PropTypes.bool,
  isProject: PropTypes.bool,
}

SEO.defaultProps = {
  title: config.siteTitle,
  pagePath: '',
  description: config.siteDescription,
  image: {
    src: config.siteUrl + config.shareImage,
    width: config.shareImageWidth,
    height: config.shareImageHeight,
  },
  isPage: false,
  isProject: false,
}

export default SEO
