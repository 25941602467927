module.exports = {
  siteTitle: 'Wooco Arquitectas',
  siteTitleAlt:
    'Estudio de Arquitectura e Interiorismo en Las Palmas de Gran Canaria', // This allows an alternative site title for SEO schema.
  publisher: 'Wooco Arquitectas', // Organization name used for SEO schema
  siteDescription:
    'Estudio de arquitectura e interiorismo de Las Palmas de Gran Canaria.',
  siteUrl: 'https://wooco.es', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  author: 'Wooco Arquitectas', // Author for RSS author segment and SEO schema
  authorUrl: 'https://wooco.es/nosotras', // URL used for author and publisher schema, can be a social profile or other personal site
  shortTitle: 'Wooco', // Used for App manifest e.g. Mobile Home Screen
  shareImage: '/logos/compartir.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 1200, // Change to the width of your default share image
  shareImageHeight: 849, // Change to the height of your default share image
  siteLogo: '/logos/seo.png', // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#e9e9e9', // Used for Offline Manifest
  themeColor: '#121212', // Used for Offline Manifest
  copyright: 'Copyright © 2018 Wooco', // Copyright string for the RSS feed
}
