import { graphql } from 'gatsby'
import React from 'react'

import SEO from '../components/SEO'
import { HomeTemplate } from '../templates/home'

const HomePage = ({ data }) => {
  const {
    autoTypingBase,
    autoTypingTexts,
    homeDescription: { homeDescription },
    backgroundImage,
  } = data.contentfulConfiguracionGeneral

  const {
    metaTitle,
    metaDescription: { metaDescription },
  } = data.contentfulPaginas

  return (
    <React.Fragment>
      <SEO title={metaTitle} description={metaDescription} />
      <HomeTemplate
        autoTypingBase={autoTypingBase}
        autoTypingTexts={autoTypingTexts}
        homeDescription={homeDescription}
        backgroundImage={backgroundImage}
      />
    </React.Fragment>
  )
}

export const query = graphql`
  query {
    contentfulConfiguracionGeneral {
      autoTypingBase
      autoTypingTexts
      homeDescription {
        homeDescription
      }
      backgroundImage {
        title
        description
        fluid(maxWidth: 2200) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    contentfulPaginas(slug: { eq: "home" }) {
      metaTitle
      metaDescription {
        metaDescription
      }
    }
  }
`

export default HomePage
